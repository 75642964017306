.h1-v {
  text-align: center;
  font-size: 3em;
  margin-top: 130px;
  color: #00695c;
}

.villas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  margin-top: 150px;
  margin-bottom: 150px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 500px;
  z-index: -1;
  object-fit: cover;
  margin-top: -150px;
  opacity: 0.9;
}

.villa-card {
  flex: 1 1 calc(33.33% - 30px);
  max-width: calc(33.33% - 90px);
  background: white;
  border: 1px solid #e0e0e0;
  // border-radius: 10px;
  width: 520px;
  text-align: center;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-bottom: 20px; /* Add padding for the content */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 08px20pxrgba(0, 0, 0, 0.2);
  }

  .villa-image-container {
    width: 100%;
    height: 40%;
    overflow: hidden;
    border-bottom: 1px solid #e0e0e0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .villa-content {
    padding: 20px;

    h2 {
      font-size: 1.4em;
      color: #333;
      margin-bottom: 10px;
      font-weight: bold;
    }

    p {
      font-size: 1em;
      color: #666;
      margin-top: 10px;
      margin-bottom: 20px; /* Adjust margin as needed */
    }

    span {
      border-color: #ebebeb;
    }

    .book-now {
      background-color: #00695c;
      color: #fff;
      height: 40px;
      width: 150px;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #004d40;
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .villas {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .villa-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 60px);
  }
}

@media (max-width: 768px) {
  .h1-v {
    font-size: 2.5em;
    margin-top: 150px;
  }

  .villas {
    margin-top: 170px;
    margin-bottom: 100px;
    padding: 15px;
    gap: 20px;
  }

  .villa-card {
    flex: 1 1 100%;
    max-width: 100%;
    padding-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .h1-v {
    font-size: 2em;
    margin-top: 100px;
  }

  .villas {
    margin-top: 130px;
    margin-bottom: 80px;
    padding: 10px;
    gap: 15px;
  }

  .background {
    height: 250px;
    margin-top: -20px;
  }

  .villa-card {
    padding-bottom: 10px;

    .villa-content {
      padding: 15px;

      h2 {
        font-size: 1.2em;
      }

      p {
        font-size: 0.9em;
        margin-bottom: 15px;
      }

      .book-now {
        height: 35px;
        width: 130px;
        font-size: 0.7rem;
      }
    }
  }
}
