.hero {
  background-size: cover;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;

  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60vw;
    overflow: hidden;
    z-index: -1;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .fade-in {
    opacity: 1;
  }

  .fade-out {
    opacity: 0;
  }

  .heroText {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }


  @media (max-width: 1281px) { /* Mobile */
    height: 400px;

    .background {
      margin-top: -30px;
    }

    .heroText {
      margin-top: -60px;
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.2em;
      }
    }
  }

  @media (max-width: 1025px) { /* Mobile */
    height: 400px;

    .background {
      margin-top: -30px;
    }

    .heroText {
      margin-top: -100px;
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.2em;
      }
    }
  }

  @media (max-width: 821px) { /* Mobile */
    height: 400px;

    .background {
      margin-top: -30px;
    }

    .heroText {
      margin-top: -130px;
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.2em;
      }
    }
  }

  @media (max-width: 769px) { /* Mobile */
    // height: 400px;

    .background {
      margin-top: 0px;
    }

    .heroText {
      margin-top: -130px;
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.2em;
      }
    }
  }

  @media (max-width: 767px) { /* Mobile */
    height: 400px;

    .background {
      margin-top: 90px;
    }

    .image-wrapper {
      height: 1050px;
      margin-top: -115px;
    }

    .heroText {
      margin-top: -60px;
    }

    .white-logo {
      width: 250px;
      height: 250px;
      margin-top: 550px;
    }
  }

  @media (max-width: 411px) { /* Mobile-Specific Styles */
    height: 350px;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      object-fit: cover;
    }

    .heroText {
      margin-top: -35px;
      h1 {
        font-size: 2em;
      }

      p {
        font-size: 1em;
      }
    }
  }

  @media (max-width: 390px) { /* Small Mobile */
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 90%;
      object-fit: cover;
    }

    .heroText {
      margin-top: -50px;
      h1 {
        font-size: 1.8em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }

  @media (max-width: 375px) { /* Small Mobile */
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      object-fit: cover;
    }

    .heroText {
      margin-top: -50px;
      h1 {
        font-size: 1.8em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }

  @media (max-width: 344px) { /* Small Mobile */
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .heroText {
      margin-top: -50px;
      h1 {
        font-size: 1.8em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }

  @media (max-width: 320px) { /* Extra Small Mobile */
    height: 250px;

    .background {
      height: 100vw;
    }

    .heroText {
      h1 {
        font-size: 1.5em;
      }

      p {
        font-size: 0.8em;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) { /* Tablet */
    height: 600px;

    .white-logo {
      height: 300px;
      width: 300px;
    }

    .background {
      height: 60vw;
    }

    .heroText {
      h1 {
        font-size: 2.8em;
      }

      p {
        font-size: 1.4em;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) { /* Laptop */
    height: 650px;

    .background {
      height: 60vw;
    }

    .heroText {
      h1 {
        font-size: 3em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }

  @media (min-width: 1200px) { /* Desktop */
    height: 700px;

    .background {
      height: 60vw;
    }

    .heroText {
      h1 {
        font-size: 3.2em;
      }

      p {
        font-size: 1.6em;
      }
    }
  }
}










// .hero {
//   background-size: cover;
//   height: 700px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   text-align: center;
//   position: relative;

//   .background {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 60vw;
//     z-index: -1;
//     object-fit: cover;
//     margin-top: -200px;
//   }

//   .heroText {
//     position: absolute;
//     top: 65%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;

//     h1 {
//       font-size: 3em;
//       font-family: Georgia;  
//     }

//     p {
//       font-size: 1.5em;
//       font-family: Georgia;
//     }
//   }

//   @media (max-width: 767px) { /* Mobile */
//     height: 400px;

//     .background {
//       margin-top: 90px;
//     }

//     .heroText {
//       h1 {
//         font-size: 2.5em;
//       }

//       p {
//         font-size: 1.2em;
//       }
//     }
//   }

//   @media (max-width: 431px) { /* Mobile-Specific Styles */
//     height: 350px;

//     .background {
//       height: 60vw;
//     }

//     .heroText {
//       margin-top: -20px;
//       h1 {
//         font-size: 2em;
//       }

//       p {
//         font-size: 1em;
//       }
//     }
//   }

//   @media (max-width: 431px) { 
//     .background {
//       margin-top: 70px;
//     }
//     .heroText {
//       margin-top: -45px;
//       h1 {
//         font-size: 2em;
//       }

//       p {
//         font-size: 1em;
//       }
//     }
//   }

//   @media (max-width: 375px) { /* Small Mobile */
//     height: 300px;

//     .background {
//       height: 90vw;
//     }

//     .heroText {
//       h1 {
//         font-size: 1.8em;
//       }

//       p {
//         font-size: 0.9em;
//       }
//     }
//   }

//   @media (max-width: 320px) { /* Extra Small Mobile */
//     height: 250px;

//     .background {
//       height: 100vw;
//     }

//     .heroText {
//       h1 {
//         font-size: 1.5em;
//       }

//       p {
//         font-size: 0.8em;
//       }
//     }
//   }

//   @media (min-width: 768px) and (max-width: 991px) { /* Tablet */
//     height: 600px;

//     .background {
//       height: 60vw;
//     }

//     .heroText {
//       h1 {
//         font-size: 2.8em;
//       }

//       p {
//         font-size: 1.4em;
//       }
//     }
//   }

//   @media (min-width: 992px) and (max-width: 1199px) { /* Laptop */
//     height: 650px;

//     .background {
//       height: 60vw;
//     }

//     .heroText {
//       h1 {
//         font-size: 3em;
//       }

//       p {
//         font-size: 1.5em;
//       }
//     }
//   }

//   @media (min-width: 1200px) { /* Desktop */
//     height: 700px;

//     .background {
//       height: 60vw;
//     }

//     .heroText {
//       h1 {
//         font-size: 3.2em;
//       }

//       p {
//         font-size: 1.6em;
//       }
//     }
//   }
// }
