.language-switcher {
    display: flex;
    gap: 10px;

    span {
      color: white;
    }
    
    button {
      background-color: transparent;
      border: 1px solid white;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  