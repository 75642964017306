.contact {
  padding: 60px 20px;
  text-align: center;
  background-color: #f5f5f5;

  h2,
  h3 {
    margin-bottom: 20px;
    font-size: 32px;
    color: #00695c;
  }

  .mapContainer {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    
    img {
      width: 1500px;
      border-radius: 10px;
      margin-left: -350px;
    }
    
    .error-message {
      color: red;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
    
    .contactForm {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      max-width: 500px;
      background: rgba(255, 255, 255, 0.5);
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      input, textarea {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.5); 
        color: #333; 
        font-size: 16px;

        &::placeholder {
          color: #666; 
        }
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #00695c;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          background-color: #004d40;
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .mapContainer {
      img {
        width: 1200px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        margin-left: -200px;
      }
      .contactForm {
        position: absolute;
        top: 50%;
        left: 90%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        max-width: 350px;
        background: rgba(255, 255, 255, 0.5);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 1025px) {
    .mapContainer {
      img {
        width: 1000px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        margin-left: -100px;
      }
      .contactForm {
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        max-width: 250px;
        background: rgba(255, 255, 255, 0.5);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 854px) {
    .mapContainer {
      img {
        width: 100%; 
        max-width: 1500px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
      }
      
      .contactForm {
        position: relative;
        margin-top: 25vw;
        margin-left: -30vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82vw;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.8); 
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 821px) { 
    .mapContainer {
      img {
        width: 100%; 
        max-width: 1500px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
      }
      
      .contactForm {
        position: relative;
        margin-top: 25vw;
        margin-left: -30vw;
        margin-bottom: -100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82vw;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.8); 
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 769px) { 
    .mapContainer {
      img {
        width: 100%; 
        max-width: 1500px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
      }
      
      .contactForm {
        position: relative;
        margin-top: 25vw;
        margin-left: -30vw;
        margin-bottom: -100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82vw;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.8); 
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 767px) {
    .mapContainer {
      img {
        width: 100%; 
        max-width: 1500px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
      }
      
      .contactForm {
        position: relative;
        margin-top: 25vw;
        margin-left: -30vw;
        margin-bottom: -100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82vw;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.8); 
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 680px) {
    .mapContainer {
      img {
        width: 100%; 
        max-width: 1500px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
      }
      
      .contactForm {
        position: relative;
        margin-top: 30vw;
        margin-left: -30vw;
        margin-bottom: -100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82vw;
        max-width: 500px;
        background: rgba(255, 255, 255, 0.8); 
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
  

  @media (max-width: 540px) { 
    .contact {
      margin-bottom: 50px;
    }

    .mapContainer {
      .contactForm {
        width: 85vw; 
        padding: 20px;
        margin-left: -150px;
        margin-top: 180px;
      }
    }
  }

  @media (max-width: 500px) { 
    .contact {
      margin-bottom: 50px;
    }

    .mapContainer {
      .contactForm {
        width: 80vw; 
        padding: 20px;
        margin-left: -135px;
        margin-top: 180px;
      }
    }
  }

  @media (max-width: 431px) { 
    .contact {
      margin-bottom: 50px;
    }

    .mapContainer {
      .contactForm {
        width: 80vw; 
        padding: 20px;
        margin-left: -118px;
      }
    }
  }

  @media (max-width: 414px) { 
    .mapContainer {
      .contactForm {
        width: 81vw; 
        padding: 20px;
        margin-left: -112px;
      }
    }
  }

  @media (max-width: 390px) {
    .mapContainer {
      .contactForm {
        width: 79vw; 
        padding: 20px;
        margin-left: -105px;
      }
    }
  }

  @media (max-width: 375px) { 
    .mapContainer {
      .contactForm {
        width: 79vw; 
        padding: 20px;
        margin-left: -100px;
      }
    }
  }

  @media (max-width: 360px) { 
    .mapContainer {
      .contactForm {
        width: 79vw; 
        padding: 20px;
        margin-left: -95px;
        margin-top: 190px;
      }
    }
  }

  @media (max-width: 344px) { 
    .mapContainer {
      .contactForm {
        width: 76vw; 
        padding: 20px;
        margin-left: -90px;
        margin-top: 190px;
      }
    }
  }
}
