.footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;

  .footerSection ul {
    list-style-type: none;
    padding: 0;
    display: inline-grid;
  }
  
  .footerSection ul li {
    margin-bottom: 10px;
  }
  
  .footerSection .socialMediaIcons li {
    display: inline-block;
    margin-right: 15px;
  }
  
  .footerSection .socialMediaIcons a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .footerSection .socialMediaIcons a span {
    margin-left: 8px;
  }
  

  .footerContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;

    .footerSection {
      flex: 1;
      padding: 0 15px;
      min-width: 150px; // Ensure sections don't get too narrow

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            color: #fff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &.logoSection {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 150px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .footerBottom {
    border-top: 1px solid #444;
    padding-top: 10px;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .footerContent {
      flex-direction: column;
      align-items: center;
    }

    .footerSection {
      margin-bottom: 20px;
    }

    .footerSection.logoSection {
      img {
        max-width: 120px;
      }
    }
  }
}
