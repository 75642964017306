.about {
  padding: 60px 20px;
  text-align: center;
  margin-top: 80px;

  h2 {
    margin-bottom: 50px;
    font-size: 32px;
    color: #00695c;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3rem;

    .text-container {
      flex: 1;
      margin-right: 20px;
      text-align: left;
    }

    .video-container {
      flex: 1;
      text-align: center;
    }

    h6 {
      margin-bottom: 80px;
      font-size: 24px;
      color: #00695c;
    }

    p {
      margin-bottom: 20px;
      font-size: 18px;
      text-align: justify;
    }

    video {
      width: 100%;
      max-width: 850px;
      height: auto;
    }
  }

  @media (max-width: 1281px) { /* Tablet */
    .content {
      flex-direction: column;
      align-items: center;

      .text-container {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .video-container {
        margin-bottom: 20px;
      }

      h6 {
        margin-bottom: 40px;
        text-align: center;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1025px) { /* Tablet */
    .content {
      flex-direction: column;
      align-items: center;

      .text-container {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .video-container {
        margin-bottom: 20px;
      }

      h6 {
        margin-bottom: 40px;
        text-align: center;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 991px) { /* Tablet */
    .content {
      flex-direction: column;
      align-items: center;

      .text-container {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .video-container {
        margin-bottom: 20px;
      }

      h6 {
        margin-bottom: 40px;
        text-align: center;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 767px) { /* Mobile */
    padding: 40px 10px;
    margin-top: 40px;

    h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }

    .content {
      flex-direction: column;
      padding: 1rem;

      .text-container {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .video-container {
        margin-bottom: 20px;
      }

      h6 {
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
      }

      p {
        font-size: 14px;
      }

      video {
        max-width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 431px) { /* Mobile-Specific Styles */
    padding: 0px;
    margin-top: 20px;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .content {
      .text-container {
        margin-bottom: 15px;
      }

      .video-container {
        margin-bottom: 15px;
      }

      h6 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
      }

      video {
        max-width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 415px) { /* Mobile-Specific Styles */
    padding: 0px;
    margin-top: 20px;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .content {
      .text-container {
        margin-bottom: 15px;
      }

      .video-container {
        margin-bottom: 15px;
      }

      h6 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
      }

      video {
        max-width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 375px) { /* Mobile-Specific Styles */
    padding: 0px;
    margin-top: 40px;

    .about {
      padding: 1rem;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .content {
      .text-container {
        margin-bottom: 15px;
      }

      .video-container {
        margin-bottom: 15px;
      }

      h6 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
      }

      video {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
