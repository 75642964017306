.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .modal-close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #00695c;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    &:hover {
      background-color: #004d40;
    }
  }

  @media (max-width: 768px) {
    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 10px;
      max-width: 500px;
      width: 80%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
    }
  }